import moment from "moment";
export var initState = {
    id: "",
    dim_mst_expense_status_id: "",
    dim_mst_price_category_id: 2,
    dim_new_alldernow_emp_id: "",
    dim_supplier_information_id: "",
    dim_new_gr_id: "",
    doc_number: "",
    gr_refer: "",
    full_supplier_name: "",
    full_supplier_address_name: "",
    supplier_phone_number: "",
    supplier_nickname: "",
    tax_invoice_number: "",
    tax_invoice_date: "",
    dim_mst_discount_type_id: 1,
    discount_value: 0,
    total_price_before_discount: 0,
    discount_price: 0,
    total_price_after_discount: 0,
    total_price_product_no_vat: 0,
    total_price_product_zero_vat: 0,
    total_price_product_seven_vat: 0,
    total_seven_vat_amount: 0,
    total_price_vat: 0,
    total_price_before_vat: 0,
    grand_total_price: 0,
    remark: "",
    doc_issue_date: moment(new Date()).format("YYYY-MM-DD"),
    doc_due_date: "",
    created_at: "",
    updated_at: "",
    dimMstExpenseStatus: "",
    factExpenseItem: [],
    factNewItemExpenseSort: [],
    createdExpenseEmp: ""
};
